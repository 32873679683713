import React from "react"
import SEO from "../components/Seo"
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404" />
    <div id="notfound">
    <div className="notfound">
    <div className="notfound-404">
    <h1>:(</h1>
    </div>
    <h2>404 - Page not found</h2>
    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
    <Link to="/" className="btn btn-mock">home page</Link>
    </div>
    </div>
  </React.Fragment>
)

export default NotFoundPage
